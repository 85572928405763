import React from "react"
import styles from "./Main.module.css"
import { Top } from "./Top/Top"
import { Mid } from "./Mid/Mid"
import { MidBot } from "./Mid/MidBot/MidBot"
import { Bot } from "./Bot/Bot"

export const Main = () => {
    return (
        <main className={styles.Main}>
            <Top />
            <Mid />
            <MidBot />
            <Bot />
        </main>
    )
}
