import React from "react"
import styles from "./MidBot.module.css"

export const MidBot = () => {
    return (
        <div className={styles.midBotContainer}>
            <div className={styles.midInnerContainer}>
                <div className={styles.c1}>
                    <p>Müşteri Memnuniyeti</p>
                </div>
                <div className={styles.c2}>
                    <p>Güvenilirlik</p>
                </div>

                <div className={styles.c3}>
                    <p>Yaratıcı Çözümler</p>
                </div>

                <div className={styles.c4}>
                    <p>Kalite Odaklılık</p>
                </div>
            </div>
        </div>
    )
}
