import React from "react"
import styles from "./Bot.module.css"
import { ReactComponent as Schedule } from "../../../assets/svgs/schedule.svg"

export const Bot = () => {
    return (
        <div className={styles.botContainer}>
            <div className={styles.leftContainer}>
                <div className={styles.imgContainer}>
                    <Schedule className={styles.img} />
                </div>
                <h1 className={styles.h1}>Hizmetlerimiz</h1>
            </div>
            <div className={styles.rightContainer}>
                <ul className={styles.listLeft}>
                    <li>Sosyal Medya Yönetimi</li>
                    <li>Marka Stratejisi ve Geliştirme</li>
                    <li>İçerik Üretimi ve Yönetimi</li>
                    <li>Web Sitesi Tasarımı ve Geliştirme</li>
                    <li>SEO (Arama Motoru Optimizasyonu)</li>
                    <li>Dijital Reklam Yönetimi</li>
                </ul>
                <ul className={styles.listRight}>
                    <li>Meta Reklamları</li>
                    <li>Google Ads Reklamları</li>
                    <li>Video Prodüksiyon ve Düzenleme</li>
                    <li>Reklam Performans Analizi</li>
                    <li>Reklam Kampanyası Optimizasyonu</li>
                    <li>Hedef Kitle Analizi</li>
                </ul>
            </div>
        </div>
    )
}
