import React from "react"
import styles from "./ListItem.module.css"
import { ReactComponent as Schedule } from "../../../../assets/svgs/schedule.svg"
import { ReactComponent as Plane } from "../../../../assets/svgs/plane.svg"
import { ReactComponent as Pen } from "../../../../assets/svgs/pen.svg"

import { ReactComponent as Tube } from "../../../../assets/svgs/tube.svg"
export const ListItem = ({ img, header, text }) => {
    return (
        <div className={styles.listItem}>
            <div className={styles.imageContainer}>
                {img === "Schedule" && <Schedule className={styles.img} />}
                {img === "Pen" && <Pen className={styles.img} />}

                {img === "Plane" && <Plane className={styles.img} />}
                {img === "Tube" && <Tube className={styles.img} />}
            </div>
            <h3 className={styles.headerText}>{header}</h3>
            <p className={styles.text}> {text} </p>
        </div>
    )
}
