import React from "react"
import styles from "./Footer.module.css"
import { ReactComponent as Logo } from "../../assets/svgs/UDSB.svg"
import { ReactComponent as Instagram } from "../../assets/svgs/instagram.svg"

export const Footer = () => {
    return (
        <footer className={styles.footer}>
            <Logo className={styles.logo} />
            <div className={styles.textContainer}>
                <p className={styles.text2}>İşiniz için dijital çözüm</p>
                <p className={styles.text}>USTK Digital Solutions</p>
            </div>
            <a href="https://www.instagram.com/ustkds/" target="_blank">
                <Instagram className={styles.instagram} />
            </a>
        </footer>
    )
}
