import React from "react"
import styles from "./Top.module.css"
import { ReactComponent as Vector } from "../../../assets/svgs/dotdot.svg"
import fimage from "../../../assets/images/unsplash.avif"

export const Top = () => {
    return (
        <div className={`${styles.top} ${styles.fadeInLeft}`}>
            <div className={styles.topContainer}>
                <div className={styles.imageContainer}>
                    <img className={styles.image} src={fimage} alt="Logo" />
                </div>
                <div className={styles.topTextContainer}>
                    <h1 className={styles.topText}>
                        Yaratıcı fikirlerle çözümler sunuyoruz
                        <div className={styles.spanCont}></div>
                    </h1>
                    <p className={styles.textEl}>
                        Markanızın güçlü bir etki yaratması için yenilikçi ve
                        etkili çözümler geliştiriyoruz. Dijital dünyada öne
                        çıkmak ve müşterilerinizle sağlam bağlar kurmak için
                        stratejik ve yaratıcı destek sağlıyoruz.
                    </p>
                </div>
            </div>
        </div>
    )
}
