import React from "react"
import styles from "./Header.module.css"
import { ReactComponent as Logo } from "../../assets/svgs/UDS.svg"
import { ReactComponent as Hamburgermenu } from "../../assets/svgs/hamburgermenu.svg"
import { ReactComponent as Instagram } from "../../assets/svgs/instablue.svg"

export const Header = () => {
    return (
        <div className={styles.headerContainer}>
            {/* <Hamburgermenu
                className={styles.icon}
                style={{
                    marginRight: "auto",
                }}
            /> */}
            <Logo className={styles.logo} width={70} height={70} />

            <a href="https://www.instagram.com/ustkds/" target="_blank">
                <Instagram className={styles.instagram} />
            </a>
        </div>
    )
}
