import React from "react"
import styles from "./Mid.module.css"
import { ListItem } from "./ListItem/ListItem"

export const Mid = () => {
    return (
        <div className={styles.midContainer}>
            <h2 className={styles.headlineLarge}>
                Ne konuda yardımcı oluyoruz?
            </h2>
            <div className={styles.listItemContainer}>
                <ListItem
                    img={"Schedule"}
                    header={"Sosyal Medya"}
                    text={
                        "Düzenli içerik ve stratejik planlama ile sosyal medyada etkileşiminizi artırıyoruz."
                    }
                />

                <ListItem
                    img={"Plane"}
                    header={"Reklam Yönetimi"}
                    text={
                        "Anahtar kelimelerle kampanyalarınızı yöneterek hedef kitlenize ulaşıyoruz."
                    }
                />

                <ListItem
                    img={"Pen"}
                    header={"İçerik Üretimi"}
                    text={
                        "Müşterilerinizle bağ kuran ve markanızı yansıtan içerikler üretiyoruz."
                    }
                />

                <ListItem
                    img={"Tube"}
                    header={"Web Sitesi Geliştirme"}
                    text={
                        "Kullanıcı dostu web tasarımlarıyla markanızı dijitalde öne çıkarıyoruz."
                    }
                />
            </div>
        </div>
    )
}
